.menu-button {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 35px;
}

.menu-button-color {
  color: #809fb8;
}

.menu-button-svg,
.menu-button-svg path {
  fill: #809fb8;
  color: #809fb8;
}

.menu-button:hover .menu-button-svg,
.menu-button-svg:hover {
  fill: #fff;
}
